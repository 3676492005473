<template>
    <div>
        
        <b-row>

        <b-col cols="12" xl="12" >
            <table class="mt-2 mt-xl-0 w-100">
            <tr class="mb-1">
                <th class="pb-50" style="width: 10%">
                <div class="d-flex align-items-center">
            
                <div class="ml-1">
                    <h6 class="mb-0 mediumFonts">
                    Level 1 Remarks
                    </h6>
                <!--  <small>Monthly Sales</small> -->
                </div>
                </div>
                </th>
                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                    <span v-b-tooltip.hover.v-primary.top :title="item.level_one_user.role + ' - ' +item.level_one_user_remarks | removeUnderCapitalize" class="wordBreak" v-if="(item.level_one_user_remarks != null && item.level_one_user_remarks != '') && item.level_one_user_remarks.length > 50">{{item.level_one_user.role | removeUnderCapitalize}} - {{item.level_one_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else-if="(item.level_one_user_remarks != null && item.level_one_user_remarks != '') && item.level_one_user_remarks.length <= 50">{{item.level_one_user.role | removeUnderCapitalize}} - {{item.level_one_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else>{{'N/A'}}</span>
                </td>

                <th class="pb-50" style="width: 5%">
                <div class="d-flex align-items-center">
            
                <div class="ml-1">
                    <h6 class="mb-0 mediumFonts">
                        Level 2 Remarks
                    </h6>
                
                </div>
                </div>
                </th>
                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                    <span v-b-tooltip.hover.v-primary.top :title="item.level_two_user.role + ' - ' +item.level_two_user_remarks | removeUnderCapitalize" class="wordBreak" v-if="(item.level_two_user_remarks != null && item.level_two_user_remarks != '') && item.level_two_user_remarks.length > 50">{{item.level_two_user.role | removeUnderCapitalize}} - {{item.level_two_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else-if="(item.level_two_user_remarks != null && item.level_two_user_remarks != '') && item.level_two_user_remarks.length <= 50">{{item.level_two_user.role | removeUnderCapitalize}} - {{item.level_two_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else>{{'N/A'}}</span>
                </td>

                <th class="pb-50" style="width: 5%">
                <div class="d-flex align-items-center">
            
                <div class="ml-1">
                    <h6 class="mb-0 mediumFonts">
                    Level 3 Remarks
                    </h6>
                
                </div>
                </div>
                </th>
                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                    <span v-b-tooltip.hover.v-primary.top :title="item.level_three_user.role + ' - ' +item.level_three_user_remarks | removeUnderCapitalize" class="wordBreak" v-if="(item.level_three_user_remarks != null && item.level_three_user_remarks != '') && item.level_three_user_remarks.length > 50">{{item.level_three_user.role | removeUnderCapitalize}} - {{item.level_three_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else-if="(item.level_three_user_remarks != null && item.level_three_user_remarks != '') && item.level_three_user_remarks.length <= 50">{{item.level_three_user.role | removeUnderCapitalize}} - {{item.level_three_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else>{{'N/A'}}</span>
                </td>

            </tr>
            
            <tr class="mb-1">
                <th class="pb-50" style="width: 10%">
                <div class="d-flex align-items-center">
            
                <div class="ml-1">
                    <h6 class="mb-0 mediumFonts">
                    Reject Reason
                    </h6>
                <!--  <small>Monthly Sales</small> -->
                </div>
                </div>
                </th>
                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                    <span v-if="item.reject_comment && item.reject_comment.length > 50" v-b-tooltip.hover.v-primary.top :title="item.reject_comment" class="wordBreak">{{item.reject_comment | capitalize | truncate}}</span>
                    <span v-else class="wordBreak">{{item.reject_comment | capitalize}}</span>
                </td>

            

                <th class="pb-50" style="width: 5%" v-if="form_type != 'Micron External Checklist Report'">
                <div class="d-flex align-items-center">
            
                <div class="ml-1">
                    <h6 class="mb-0 mediumFonts" v-if="!['Flash Times', 'Quality & Safety Assessment'].includes(form_type)">
                    Building
                    </h6>
                    <h6 class="mb-0 mediumFonts" v-else>
                    Location
                    </h6>
                
                </div>
                </div>
                </th>
                
                <th class="pb-50" style="width: 5%" v-else>
                <div class="d-flex align-items-center">
            
                <div class="ml-1">
                    <h6 class="mb-0 mediumFonts">
                    Checked Date
                    </h6>
                    
                
                </div>
                </div>
                </th>
                
                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;" v-if="!['Flash Times', 'Quality & Safety Assessment','Micron External Checklist Report','Stock Inventory Form Report'].includes(form_type)">
                    <span v-if="getBuilding(item.checkListData).length > 50" v-b-tooltip.hover.v-primary.top :title="getBuilding(item.checkListData)" class="wordBreak">{{getBuilding(item.checkListData) | truncate}}</span>
                    <span v-else class="wordBreak">{{getBuilding(item.checkListData)}}</span>
                </td>

                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;" v-else-if="!['Micron External Checklist Report','Stock Inventory Form Report'].includes(form_type)">
                    <span v-if="getLocation(item.checklistData).length > 50" v-b-tooltip.hover.v-primary.top :title="getLocation(item.checklistData)" class="wordBreak">{{getLocation(item.checklistData) | truncate}}</span>
                    <span v-else class="wordBreak">{{getLocation(item.checklistData)}}</span>
                </td>

                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;" v-else>
                    <span>{{sitedateTime2(item.checked_date,date_format,'' )}}</span>
                </td>

            </tr>

            <tr class="mb-1" v-if="!['Micron External Checklist Report','Stock Inventory Form Report'].includes(form_type)">
                <th class="pb-50" style="width: 10%" >
                <div class="d-flex align-items-center">
            
                <div class="ml-1">
                    <h6 class="mb-0 mediumFonts" v-if="!['Flash Times', 'Quality & Safety Assessment'].includes(form_type)">
                    Checked Date
                    </h6>
                    <h6 class="mb-0 mediumFonts" v-else>
                    Date
                    </h6>
                </div>
                </div>
                </th>
                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;" v-if="!['Flash Times', 'Quality & Safety Assessment'].includes(form_type)">
                <span class="wordBreak">{{sitedateTime(item.checked_date,date_format,'' )}}</span>
                </td>
                <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;" v-else>
                <span>{{sitedateTime2(item.date,date_format,'' )}}</span>
                </td>

            </tr>
    
            
            </table>
        </b-col>

        </b-row>

    </div>
</template>

<script>
import {
    BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile
} from 'bootstrap-vue';


export default{
    name: 'ReportViewComponent',
    components: {
        BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormFile
    },
    props: ['item', 'form_type', 'date_format'],
    directive:{

    },
    data(){
        return{
        }
    },
    methods: {
         getLocation(item){
          var arr = [];

          if (item.length > 0) {
            if(this.form_type=='Quality & Safety Assessment'){
                arr = item.map(t => { return t.location_name });
            }
            else{
                 arr = item.map(t => { return t.building.name + ' - '+ t.code });
            }
           
          }

          arr = arr.join(', ');
          return arr;
        },
        getBuilding(item){
        var arr = [];

        if (item.length > 0) {

          arr = item.map(t => { return t.building_name });
        }

        arr = arr.join(', ');
        return arr;
      },
    },
    mounted(){
        console.log(this.item, this.form_type, this.date_format);
    }
}
</script>
